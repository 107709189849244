<template>
  <div>
    <AppMenu />
    <TeamCompleteSection/>
    <FooterSection />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";
import FooterSection from "../components/FooterSection.vue";
import TeamCompleteSection from "../components/TeamCompleteSection.vue";


export default {
  name: "Team",
  components: {
    AppMenu,
    FooterSection,
    TeamCompleteSection
},
};
</script>
